import React,{useState,useEffect} from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import Source from './Source';
function Massage_single({msg,type,re,error=null}) {
  const [sources,setSources]=useState([])
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

 

  useEffect(() => {
    if(type===2){
      setSources(msg)
    }
  
  }, [])
  
  const openModel=(msg)=>{
    handleOpen()
  }

  return (
    <div className='massage-wrap' ref={re}>
    {!error?
        type==2?
        <div className='source'>
              <a className='src' onClick={(msg)=>openModel(msg)}>{'Source '}<InfoIcon className='' color="primary"/></a>
              <Source list={sources} open={open} setOpen={setOpen}/>
        </div>
        :
        <div className={type==0?'massage ruso':'massage user'}>
                {msg}
        </div>
        
        :
        <div className='massage ruso error'>
                {msg}
        </div>
    }
    </div>
  )
}

export default Massage_single