import React, { PureComponent } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import "src/styles/modal.scss"

function PreviewModal({ open, onClose, title, children }) {

    return (
        <>
            <Modal
                open={open}
                onClose={onClose}
            >
                <Box id="preview-modal" className="modal" style={{ width: "auto" }}>
                    <div className="preview-modal">
                        <div className='preview-modal-header'>
                            <h2>
                                {title}
                            </h2>
                        </div>
                        <hr className="primary"/>
                        <div className='preview-modal-body'>
                            {children}
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    );
}

export default PreviewModal;