import React from 'react';
import logo from "../../assets/img/Diplo-logo-transparent.png"
import { CHAT_PAGE_ROUTE, REPORT_PAGE_ROUTE, SPEAKERS_PAGE_ROUTE, TRANSCRIPT_PAGE_ROUTE } from '../../utils/routes';
import BurgerMenu from './BurgerMenu';
import { useResponsive } from 'src/hooks/useResponsive';
import { useLocation, Navigate, useParams, useSearchParams } from "react-router-dom"
function Navbar() {

    const { isTablet } = useResponsive();
    let { id } = useParams();
    return (
        // <div className='nav nav-top'>

        //     <div className='nav-inner'>

        //         <div className='nav-left'>
        //             <a className="nav-icon" href='/'>
        //                 <img src={logo} alt="" />
        //             </a>
        //         </div>

        //         {
        //             isTablet ? <div className="nav-centar">
        //                 <a href={TRANSCRIPT_PAGE_ROUTE}>
        //                     Transcript
        //                 </a>
        //                 <a href={CHAT_PAGE_ROUTE}>
        //                     AI Chat
        //                 </a>
        //                 <a href={SPEAKERS_PAGE_ROUTE}>
        //                     Speakers Analysis
        //                 </a>
        //                 <a href={REPORT_PAGE_ROUTE}>
        //                     Summary Report
        //                 </a>
        //             </div>
        //                 : <div className="nav-right">
        //                     <BurgerMenu />
        //                     </div>
        //         }

        //     </div>

        // </div>

        <div className='nav-btn-wrap nav-top'>



            <div className="nav-centar">
                <a href={'/' + id + REPORT_PAGE_ROUTE}>
                    Summary Report
                </a>
                <a href={'/' + id + CHAT_PAGE_ROUTE}>
                    AI Chat
                </a>
                <a href={'/' + id + SPEAKERS_PAGE_ROUTE}>
                    Statements
                </a>
            </div>


        </div>
    );
}

export default Navbar;