import React, { useRef, useState, useEffect } from 'react'
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import SpeakerStat from './SpeakerStat';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getInitials } from 'src/utils/speakersHelper';
function SpeakerDesc(props) {
  const select = useRef('')


  const handleChange = (event) => {
    props.setSelected(event.target.value);
  };


  const renderSpeakerImage = (img, name) => {
    if (img !== "https://freesvg.org/img/abstract-user-flat-4.png") {
      // return <img src={replaceImgSrc(speaker.flag)} loading={index > 5 ? "lazy" : ""} />
      return <img src={img} alt="test" className='speaker-img' />
    } else {
      return <div class="user-initials" style={{ backgroundColor: "#00818c" }}>
        <p class="user-initials-inner">{getInitials(name)}</p>
      </div>
    }
  }

  return (
    <div className='speaker-desc'>
      <div className='left'>

        <div className='wrap'>

          {
            renderSpeakerImage(props.speakers[props.selected]?.flag, props.speakers[props.selected]?.speaker.replace('_', ' '))
          }
          {/* <img src={props.speakers[props.selected]?.flag} alt="test" className='speaker-img'/> */}
          <h2>{props.speakers[props.selected]?.speaker.replace('_', ' ')}</h2>
        </div>
      </div>
      <SpeakerStat speech_speed={props.speakers[props.selected]?.speech_speed} speech_length={props.speakers[props.selected]?.speech_length} graph={props.speakers[props.selected]?.graph} />

    </div>

  )
}

export default SpeakerDesc