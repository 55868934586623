import React, { Component, useRef } from 'react';
import PropTypes from 'prop-types';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Carousel } from '../common/Carousel/Carousel';

import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import DirectionsIcon from '@mui/icons-material/Directions';
import { TextField } from '@mui/material';
import { useNavigation } from 'src/hooks/useNavigation';
import { useParams } from 'react-router-dom';
import { getInitials, getSpeakersName, renderSpeakerCard, renderSpeakerImage } from 'src/utils/speakersHelper';

function SpeakersList({ title, speakers, filterSpeakersByName, handleChange }) {

    const sliderRef = useRef();
    let { id } = useParams();
    const { navigateTo } = useNavigation();

    const replaceImgSrc = (imgSrc) => {
        if (imgSrc === 'https://upload.wikimedia.org/wikipedia/commons/d/db/Ant%C3%B3nio_Guterres%2C_23.03.23.jpg') {
            return 'https://www.un.org/sg/sites/www.un.org.sg/files/733074-Guterres.jpg'
        } else if (imgSrc === 'https://www.un.org/en/ga/president/78/img/Portrait.jpg') {
            return 'https://st.adda247.com/https://wpassets.adda247.com/wp-content/uploads/multisite/sites/5/2023/06/05150723/FxkiPgUWIAQhylC.jpg'
        } else {
            return imgSrc
        }
    }


    const renderSpeakerImage = (speaker, index) => {
        if (speaker?.flag !== "https://freesvg.org/img/abstract-user-flat-4.png") {
            return <img src={replaceImgSrc(speaker.flag)} loading={index > 5 ? "lazy" : ""} />
        } else {
            return <div class="user-initials" style={{ backgroundColor: "#00818c" }}>
                <p class="user-initials-inner">{getInitials(getSpeakersName(speaker))}</p>
            </div>
        }
    }

    return (
        <>
            <div className="speakers-container card-container">
                <div className='card-container-header'>
                    <h2>{title}</h2>

                    {/* <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Search speakers"
                        variant="outlined"
                    /> */}
                    <TextField label="Search" variant="outlined" onChange={(event) => filterSpeakersByName(event.target.value)} />
                    {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton> */}

                </div>
                <hr className="primary" />
                <div className="speakers-list">
                    <Carousel
                        ref={sliderRef}
                    >
                        {
                            speakers.map((speaker, index) =>
                                <div className='new-speaker-card-out' onClick={() => handleChange(index)}>
                                    <div index={index} className="new-speaker-card">
                                        {renderSpeakerImage(speaker, index)}
                                        {renderSpeakerCard(speaker, index)}
                                        {/* <img src={replaceImgSrc(speaker.flag)} loading={index > 5 ? "lazy" : ""} /> */}
                                        {/* {console.log(speaker)}
                                        {
                                            true ?
                                                // checkIfStringCanBeSplited(speaker?.name) ?
                                                <h3>{speaker?.name}{speaker?.speaker}</h3>
                                                : <h3>{checkIfStringCanBeSplited(speaker?.name)[0]}</h3>
                                        } */}
                                        {/* <span>CFO</span>
                                        <span>DiploFoundation</span> */}
                                    </div>
                                </div>
                            )
                        }
                    </Carousel>
                </div>
            </div>

        </>
    );
}

export default SpeakersList;