import React from 'react'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Typography from '@mui/material/Typography';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${other.type}-${index}`}
      aria-labelledby={`simple-tab-${other.type}-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index,type) {
  return {
    id: `simple-tab-${type}-${index}`,
    'aria-controls': `simple-tabpanel-${type}-${index}`,
  };
}



function SpeakerSpeach(props) {
  const [valueS, setValueS] = React.useState(0);

  const [value, setValue] = React.useState(0);
  const [isReadMore, setIsReadMore] = React.useState(true);
  const [isReadMore2, setIsReadMore2] = React.useState(true);

  const toggleReadMore = () => {setIsReadMore(!isReadMore)};
  const toggleReadMore2 = () => {setIsReadMore2(!isReadMore2)};
  
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangeSpeech = (event, newValue) => {
    setValueS(newValue);
  };
  return (
    <div className='flex-column gap-20'>
      <div className='speaker-speach'>

       <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Stances" {...a11yProps(0,'stan')} />
          <Tab label="Arguments" {...a11yProps(1,'stan')} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} type={'stan'}>
          {
            props.spa.stances.map((e)=>{
              return ['- '+e,<br/>]
            })
          }
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1} type={'stan'}>
        
      {
            props.spa.arguments_list.map((e)=>{
              return ['- '+e,<br/>]
            })
          }
      </CustomTabPanel>
      
    </div>
    
    <div className='speaker-speach'>
     
    <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
        <Tabs value={valueS} onChange={handleChangeSpeech} aria-label="basic tabs example">
          <Tab label="Speech" {...a11yProps(0,'sum')} />
          <Tab label="Summary" {...a11yProps(1,'sum')} />
        </Tabs>
      </Box>
      <CustomTabPanel value={valueS} index={0} type={'sum'}>
        <p className='testimonials__quote__text'>
          {isReadMore ? props.speech.slice(0, 1000): props.speech }
          {props.speech.length > 150 &&
            
            <span onClick={toggleReadMore} className='show-more'>
              {isReadMore ? ' ...read more' : ' ...show less'}
            </span>
          }
        </p>
      </CustomTabPanel>
      <CustomTabPanel value={valueS} index={1} type={'sum'}>
      <p className='testimonials__quote__text'>
          {isReadMore2 ? props.spa.report.slice(0, 1000).split('\n').map(str => <p>{str}</p>): props.spa.report.split('\n').map(str => <p>{str}</p>) }
          {props.spa.report.length > 150 &&
            
            <span onClick={toggleReadMore2} className='show-more'>
              {isReadMore2 ? ' ...read more' : ' ...show less'}
            </span>
          }
        </p>
      </CustomTabPanel>
      
      
    </div>
  
  </div>
  )
}

export default SpeakerSpeach