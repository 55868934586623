import React from 'react';
import Footer from './Footer';
import PageTitle from '../common/PageTitle/PageTitle';

function HomeLayout({ children, pageTitle }) {
    return (
        <>
            <PageTitle>{pageTitle}</PageTitle>
            <div className="app-container">
                <main className="main-container">
                    {children}
                </main>
                <Footer />
            </div>
        </>
    );
}

export default HomeLayout;