import React, { useMemo, useState } from "react";
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    Text,
    Tooltip,
    Legend
} from "recharts";
import { useResponsive } from "src/hooks/useResponsive";
import { POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR } from 'src/utils/constants';

export const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="stacked-bar-tooltip">
                <p className="label"><b>{`${label}:`}</b> {`${payload[0].payload.total} arguments`}</p>
                <div className="values-grid">
                    {payload.map((pld) => (
                        <div className="values">
                            <div>{renderDataName(pld.dataKey)}</div>
                            <div style={{ color: pld.fill }}>{`${getTotalNumber(pld)} (${pld.value}%)`}</div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    return null;
};


export function Stats({ index }) {

    const data = {
        "arguments":
            [
                {
                    topic: "Energy",
                    positive: 7,
                    negative: 5,
                    neutral: 2,
                    total: 14,
                    positivePercentage: 50.00,
                    negativePercentage: 35.71,
                    neutralPercentage: 14.29
                },
                {
                    topic: "Finance",
                    positive: 5,
                    negative: 3,
                    neutral: 9,
                    total: 17,
                    positivePercentage: 27.78,
                    negativePercentage: 16.67,
                    neutralPercentage: 55.56
                },
                {
                    topic: "Consumer Non-Durables",
                    positive: 1,
                    negative: 7,
                    neutral: 3,
                    total: 11,
                    positivePercentage: 9.09,
                    negativePercentage: 63.64,
                    neutralPercentage: 27.27
                },
                {
                    topic: "Technology",
                    positive: 6,
                    negative: 5,
                    neutral: 3,
                    total: 14,
                    positivePercentage: 42.86,
                    negativePercentage: 35.71,
                    neutralPercentage: 21.43
                },
                {
                    topic: "Consumer Services",
                    positive: 2,
                    negative: 3,
                    neutral: 8,
                    total: 13,
                    positivePercentage: 16.67,
                    negativePercentage: 25.00,
                    neutralPercentage: 58.33
                },
                {
                    topic: "Public Utilities",
                    positive: 3,
                    negative: 5,
                    neutral: 2,
                    total: 10,
                    positivePercentage: 30.00,
                    negativePercentage: 50.00,
                    neutralPercentage: 20.00
                },
                {
                    topic: "Health Care",
                    positive: 5,
                    negative: 5,
                    neutral: 2,
                    total: 12,
                    positivePercentage: 41.67,
                    negativePercentage: 41.67,
                    neutralPercentage: 16.67
                }
            ],
        "stats": {
            topic: "Total",
            positive: 25,
            negative: 55,
            neutral: 32,
            total: 112,
            positivePercentage: 41.67,
            negativePercentage: 41.67,
            neutralPercentage: 16.67
        }
    };

    return (
        <>
            {index == -1
                ? "prazno"
                // ? <Preview data={data["stats"]} />
                : <Preview data={data["arguments"][index]} />
            }
        </>
    );
}

export const Preview = ({ data }) => {
    return (
        <div className="statistics">
            {JSON.stringify(data)}
            {/* <div className="statistics-header">
                <h3>{data.topic}</h3>
                <span className="total">{data.total}</span>
            </div>
            <div className="statistics-body">
                <div style={{ display: "inline-block", padding: 10 }}>
                    <div>Positive</div>
                    <div style={{ color: "8fd544" }}> {data["positive"]}</div>
                </div>
                <div style={{ display: "inline-block", padding: 10 }}>
                    <div>Neutral</div>
                    <div style={{ color: "8fd544" }}> {data["neutral"]}</div>
                </div>
                <div style={{ display: "inline-block", padding: 10 }}>
                    <div>Negative</div>
                    <div style={{ color: "8fd544" }}> {data["negative"]}</div>
                </div>
            </div> */}
        </div>
    );
};

function renderDataName(item) {
    switch (item) {
        case "positivePercentage":
            return "Positive arguments";
        case "neutralPercentage":
            return "Neutral arguments";
        case "negativePercentage":
            return "Negative arguments";
    }
}

function getTotalNumber(item) {
    switch (item.name) {
        case "positivePercentage":
            return item.payload.positive;
        case "neutralPercentage":
            return item.payload.neutral;
        case "negativePercentage":
            return item.payload.negative;
    }
}

function StackedBarChart({ data }) {

    const {mobileOnly} = useResponsive();

    const [activeIndex, setActiveIndex] = useState(-1);

    const colors = [POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR]

    const newActiveIndex = (data, index) => {
        setActiveIndex(index);
        console.log("====================================");
        console.log("activeIndex");
        console.log(activeIndex);
    }

    const resetActiveIndex = () => {
        setActiveIndex(-1);
    }

    return (
        <>
            {/* <div>
                <Stats index={activeIndex} />
            </div> */}

            <ResponsiveContainer width="100%" height={50 * data.length} debounce={50}>
                <BarChart
                    data={data}
                    layout="vertical"
                    // margin={{ top: 20, right: 70, left: 70, bottom: 0 }}
                    margin={mobileOnly ? {top: 20, right: -70, left: -70, bottom: 0 } : { top: 20, right: 70, left: 70, bottom: 0 }}
                >
                    <XAxis hide axisLine={false} type="number" domain={[0, 100]} />
                    <YAxis
                        yAxisId={0}
                        dataKey={"topic"}
                        type="category"
                        axisLine={false}
                        tickLine={false}
                        hide
                    />

                    {/* <Tooltip content={<CustomTooltip />} cursor={{ fill: "transparent" }} active={true} /> */}
                    <Tooltip content={<CustomTooltip />} />
                    {/* <Tooltip /> */}

                    <Legend
                        payload={
                            ["positivePercentage", "neutralPercentage", "negativePercentage"].map(
                                (item, index) => ({
                                    id: item,
                                    type: "square",
                                    value: `${renderDataName(item)}`,
                                    // value: `${item.name} (${item.value}%)`,
                                    color: colors[index % colors.length]
                                })
                            )
                        }
                    />

                    <Bar dataKey="positivePercentage" stackId="sentiment" fill={colors[0]} onMouseOver={newActiveIndex} onMouseOut={resetActiveIndex} />
                    <Bar dataKey="neutralPercentage" stackId="sentiment" fill={colors[1]} onMouseOver={newActiveIndex} onMouseOut={resetActiveIndex} />
                    <Bar dataKey="negativePercentage" stackId="sentiment" fill={colors[2]} onMouseOver={newActiveIndex} onMouseOut={resetActiveIndex} />

                </BarChart>
            </ResponsiveContainer>
        </>
    );
}

export default StackedBarChart;