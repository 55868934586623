
//check if string can be split by ',' and if can return array else return false.
export function checkIfStringCanBeSplited(string) {
    if (string.includes(',')) {
        return string.split(',')
    } else {
        return false
    }
}

export function renderSpeakerCard(speaker, index) {

    let splitedSpeaker;

    if (speaker?.name) {
        splitedSpeaker = checkIfStringCanBeSplited(speaker?.name);
        if (splitedSpeaker !== false) {
            return <>
                <h3>{splitedSpeaker[0]}</h3>
                <span>{splitedSpeaker[1]}</span>
            </>
        } else {
            return <>
                <h3>{speaker?.name}</h3>
            </>
        }
    }
    else {
        splitedSpeaker = checkIfStringCanBeSplited(speaker?.speaker);
        if (splitedSpeaker !== false) {
            return <>
                <h3>{splitedSpeaker[0]}</h3>
                <span>{splitedSpeaker[1]}</span>
            </>
        } else {
            return <>
                <h3>{speaker?.speaker}</h3>
            </>
        }
    }
}

export function getSpeakersName(speaker) {
    if (speaker?.name) {
        return speaker?.name
    } else {
        return speaker?.speaker
    }
}

export function getInitials(fullName) {

    let initials = "";
    const names = fullName.split(' ');

    // if (names.length < 2) {
    //     return '';
    // }

    for (let i = 0; i < names.length; i++) {
        initials += names[i].charAt(0).toUpperCase();
    }

    // return initials;

    const firstName = names[0];
    const lastName = names[1];

    const firstInitial = firstName.charAt(0).toUpperCase();
    const lastInitial = lastName.charAt(0).toUpperCase();

    return `${firstInitial}${lastInitial}`;
}