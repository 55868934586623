import React, { useCallback, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton } from '@mui/material';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style}}
      onClick={onClick}
      />
  );
}


export const Carousel = React.forwardRef(
  ({ slidesToShow = 1, arrows = false, dots = false, infinite = true, centerMode = true, children, ...props }, ref) => {
    const carouselRef = useRef();

    const totalRef = ref || carouselRef;

    const settings = {
      dots: true,
      infinite: false,
      slidesToShow: 7,
      slidesToScroll: 5,
      nextArrow: <SampleNextArrow className={"next-arrow"} />,
      prevArrow: <SamplePrevArrow className={"prev-arrow"} />,
      responsive: [
        {
          breakpoint: 1280,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3,
            dots: false,
          }
        },
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2,
            dots: false,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 2,
            dots: false,
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
          }
        }
      ]
    };

    const handleScroll = useCallback(
      (event) => {
        const x = event.deltaX;
        const y = event.deltaY;

        if (x > 20 && -5 < y && y < 5) {
          return totalRef?.current?.slickNext();
        }

        if (x < -20 && -5 < y && y < 5) {
          return totalRef?.current?.slickPrev();
        }
      },
      [totalRef],
    );

    const handleMouseOn = useCallback(() => {
      document.body.style.overscrollBehaviorX = 'none';
    }, []);

    const handleMouseOff = useCallback(() => {
      document.body.style.overscrollBehaviorX = 'unset';
    }, []);

    useEffect(() => {
      if (totalRef.current) {
        const slickList = totalRef.current?.innerSlider?.list;

        slickList.addEventListener('wheel', handleScroll);

        slickList.addEventListener('mouseover', handleMouseOn);

        slickList.addEventListener('mouseout', handleMouseOff);

        return () => {
          slickList.removeEventListener('wheel', handleScroll);

          slickList.removeEventListener('mouseover', handleMouseOn);

          slickList.removeEventListener('mouseout', handleMouseOff);
        };
      }
    }, [totalRef, handleScroll, handleMouseOn, handleMouseOff]);

    return (
      <Slider
        {...settings}
        ref={totalRef}
      >
        {children}
      </Slider>
    );
  },
);
