import React from 'react';
import PropTypes from 'prop-types';
import Navbar from './Navbar';
import Footer from './Footer';
import PageTitle from '../common/PageTitle/PageTitle';

ChatLayout.propTypes = {

};

function ChatLayout({ children, pageTitle }) {
    return (
        <>
            <PageTitle>{pageTitle}</PageTitle>
            <div className="chat-container">
                <Navbar/>
                <main className="main-container ">
                    {children}
                </main>
                <Footer />
            </div>
        </>
    );
}

export default ChatLayout;