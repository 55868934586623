import React from 'react';
import { Helmet } from 'react-helmet';

function PageTitle({ children }) {
    return (
        <Helmet>
            <title>{children} | Diplo AI Reporter</title>
        </Helmet>
    );
}

export default PageTitle;