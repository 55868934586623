// export const POSITIVE_ARGUMENT_COLOR = "#2FAABA";
// export const NEUTRAL_ARGUMENT_COLOR = "#0F363B";
// export const NEGATIVE_ARGUMENT_COLOR = "#3EE4FA";

// export const POSITIVE_ARGUMENT_COLOR = "#26547C";

export const POSITIVE_ARGUMENT_COLOR = "#2FAABA";
export const NEUTRAL_ARGUMENT_COLOR = "#FFD166";
export const NEGATIVE_ARGUMENT_COLOR = "#EF476F";

export const BREAKPOINTS = {
    xs: 360,
    sm: 568,
    md: 768,
    lg: 992,
    xl: 1280,
    xxl: 1920,
  };
  
  const getMedia = (breakpoint) => `(min-width: ${breakpoint}px)`;
  
  export const media = {
    xs: getMedia(BREAKPOINTS.xs),
    sm: getMedia(BREAKPOINTS.sm),
    md: getMedia(BREAKPOINTS.md),
    lg: getMedia(BREAKPOINTS.lg),
    xl: getMedia(BREAKPOINTS.xl),
    xxl: getMedia(BREAKPOINTS.xxl),
  };
  