import React, { useCallback, useEffect, useState } from 'react';
import TopicModal from 'src/components/modals/Topics/TopicModal';
import StackedBarChart from 'src/components/common/Graphs/StackedBarChart';
import iconSet from "src/assets/icon-set/selection.json";
import IcomoonReact from 'icomoon-react';
import SpeakersList from 'src/components/report/SpeakersList';
import axios from 'axios';

import { useParams } from "react-router-dom"
import { useResponsive } from 'src/hooks/useResponsive';

import 'src/styles/reportPage.scss';

function ReportPage() {

    const { isTablet } = useResponsive();

    let { id } = useParams();

    const [topicModalOpen, setTopicModalOpen] = useState(false)
    const [selectedTopic, setSelectedTopic] = useState(false)

    // const [reportData, setReportData] = useState(reportDataJSON);
    const [reportData, setReportData] = useState(null);
    const [allSpeakers, setAllSpeakers] = useState(null);
    const [speakers, setSpeakers] = useState(null);
    const [topics, setTopics] = useState(null);

    const [reportFullScreen, setReportFullScreen] = useState(false);
    const [graphFullScreen, setGraphFullScreen] = useState(false);

    useEffect(() => {
        loadData();
        const keyDownHandler = event => {
            if (event.key === 'Escape') {
                event.preventDefault();

                setReportFullScreen(false);
                setGraphFullScreen(false);
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    const loadData = () => {
        axios.get(process.env.REACT_APP_BACKEND + '/api/reports_main?file_id=' + id, {
            headers: {
                'x-token': 'zini-da-ti-kazem'
            }
        }
        ).then((response) => {

            let speakers = response.data["speakers"],
                topics = response.data["topics"].slice(0, 15),
                graph = response.data["graph"],
                main_report = response.data["main_report"];

            let responseData = {
                speakers: speakers,
                topics: topics,
                graph: graph,
                main_report: main_report
            }

            setReportData(responseData);
            setAllSpeakers(speakers);
            setSpeakers(speakers);
            setTopics(topics);

            // setReportData(response.data);
            // setAllSpeakers(response.data["speakers"]);
            // setSpeakers(response.data["speakers"]);
            // setTopics(response.data["topics"]);
        })
    }

    const [filteredData, setFilteredData] = useState(null);

    const closeTopicModal = () => {
        setTopicModalOpen(false)
    }

    const openTopicModal = (topic) => {
        setSelectedTopic(topic);
        setTopicModalOpen(true);

        let pom = topics.find(item => item.topic === topic.topic);

        setFilteredData([pom]);
    }

    const [reportFilter, setReportFilter] = React.useState('all');

    const handleReportFilterChange = (event) => {
        setReportFilter(event.target.value);
    };

    const filterSpeakersByName = (name) => {
        console.log("POZIV");
        return setSpeakers(allSpeakers.filter(speaker => speaker.name.toLowerCase().includes(name.toLowerCase())));
    }

    return (
        <div id="report-page-container">

            <div className="report-page-header">
                <h1>Summary Report</h1>
                {/* <div className="report-filter">
                    <Select
                        id="report-filter-select"
                        value={reportFilter}
                        onChange={handleReportFilterChange}
                        sx={{
                            boxShadow: 'none',
                            '.MuiOutlinedInput-notchedOutline': { border: 0 },
                        }}
                    >
                        <MenuItem value={"all"}>Please select session day</MenuItem>
                        <MenuItem value={"first-day"}>First day</MenuItem>
                        <MenuItem value={"second-day"}>Second day</MenuItem>
                        <MenuItem value={"third-day"}>Third day</MenuItem>
                    </Select>
                </div> */}
            </div>

            {
                reportData != null ? <>

                    <div className="report-page-body">

                        {/* <SpeakersList
                            title={"Speakers"}
                            speakers={speakers}
                            filterSpeakersByName={filterSpeakersByName}
                        /> */}

                        {/* <div className="speakers-container card-container">
                        <h2>Speakers</h2>
                        <hr className="primary" />
                        <div className="speakers-list">
                            {
                                speakers.map((speaker, index) =>
                                    <div key={index} className="speaker-card">
                                        <img src={speaker.img} />
                                        <h3>{speaker.name}</h3>
                                        <span>CFO</span>
                                        <span>DiploFoundation</span>
                                    </div>
                                )
                            }
                        </div>
                    </div> */}





                        <div className="sentiment-analysis-container card-container">
                            <h2>Sentiment Analysis</h2>
                            <hr className="primary" />
                            <div className='topics-list'>
                                {
                                    reportData["topics"].slice(0, 15).map((topic, index) =>
                                        <span
                                            className="topic"
                                            onClick={() => openTopicModal(topic)}
                                        >{topic.topic}</span>
                                    )
                                }
                            </div>
                            {/* {
                            isTablet &&  */}
                            <div className='topics-graphs'>
                                <div className='stacked-bar-graph'>
                                    {/* <ResizableBox> */}
                                    <StackedBarChart data={topics.slice(0, 15)} xKey="name" yKey="pv" />
                                    {/* </ResizableBox> */}
                                </div>
                            </div>
                            {/* } */}
                        </div>

                        <div className={"report-container card-container " + (reportFullScreen ? "full-screen" : "")}>
                            <div className='full-screen-max-width'>
                                <div className="report-header">
                                    <h2>Report</h2>
                                    {
                                        reportFullScreen
                                            ? <IcomoonReact
                                                iconSet={iconSet}
                                                size={28}
                                                icon="fullscreen-exit"
                                                onClick={() => setReportFullScreen(false)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            : <IcomoonReact
                                                iconSet={iconSet}
                                                size={28}
                                                icon="fullscreen"
                                                onClick={() => setReportFullScreen(true)}
                                                style={{ cursor: "pointer" }}
                                            />
                                    }
                                </div>
                                <hr className="primary" />
                                <div className="report-content"
                                >
                                    {reportData["main_report"].split('\n').map(str => <p>{str}</p>)}
                                </div>
                            </div>
                        </div>

                        <div className={"graph-analysis-container card-container " + (graphFullScreen ? "full-screen" : "")}>
                            <div className='full-screen-full-width'>
                                <div className="graph-analysis-header">
                                    <h2>Knowledge Graph Analysis of UNGA 78 (generating takes a few minutes)</h2>
                                    {
                                        graphFullScreen
                                            ? <IcomoonReact
                                                iconSet={iconSet}
                                                size={28}
                                                icon="fullscreen-exit"
                                                onClick={() => setGraphFullScreen(false)}
                                                style={{ cursor: "pointer" }}
                                            />
                                            : <IcomoonReact
                                                iconSet={iconSet}
                                                size={28}
                                                icon="fullscreen"
                                                onClick={() => setGraphFullScreen(true)}
                                                style={{ cursor: "pointer" }}
                                            />
                                    }
                                </div>
                                <hr className="primary" />
                                <div className='graph-analysis-body'>
                                    <div className='node-graph' >
                                        {/* <ResizableBox> */}
                                        <iframe src={reportData.graph} width={"100%"} height={"100%"} />
                                        {/* <iframe src="https://dev-ai.diplomacy.edu:8541/graph_1.html" width={"100%"} height={"100%"} /> */}
                                        {/* <iframe src="https://dev-ai.diplomacy.edu:8541/graph2.html" width={"100%"} height={"100%"} /> */}
                                        {/* </ResizableBox> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        selectedTopic != null &&
                        <TopicModal
                            previewModalOpen={topicModalOpen}
                            closeTopicModal={closeTopicModal}
                            selectedTopic={selectedTopic}
                            // data={data}
                            data={filteredData}
                            allSpeakers={allSpeakers}
                        />
                    }
                </>
                    : <div className="sceleton">
                        Loading
                    </div>
            }


        </div>
    );
}

export default ReportPage;