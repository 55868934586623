import React,{useEffect} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default function Source({open,setOpen,list}) {
    const [li, setLi] = React.useState([]);

   
    
  
    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
    };
  return (
    <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        maxWidth={'xl'}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Sources"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
          <ul className='list-gr'>
          {list.length >0?
            list.map(item => {
                return <li className='source_text'>
                    <h3>{item['speaker'].replaceAll('_',' ')}</h3>
                    <div className='key'>

                    {item['keywords'].map(text=>{
                      return <Chip label={text} color="primary"/>
                    })}

                    </div>
                    <p >{item['text']}</p>
                    
                    
                </li>
              }):
              <li className='source_text'> No sources from our database</li>
          }
          </ul>
          
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
  )
}
