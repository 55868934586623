import React from 'react'
import CountUp, { useCountUp } from 'react-countup';
function SpeakerStat(props) {
 

  return (
    <div className='speach-deck-wrap'>
      <div className='speach-deck'>
        <div className='speach-card'>
          <span>Speech length:</span>
          <br />
          <h2><CountUp end={props.speech_length} duration={3} suffix=''/></h2>

          <span>words</span>

        </div>
        <div className='speach-card'>
          <span>Average speech pace:</span>
          <br />
          <h2><CountUp end={props.speech_speed} duration={5} suffix=''/></h2>

          <span>words per minute</span>

        </div>
        
      </div>
      <div className="speaker-graph">
        <iframe src={props.graph} frameborder="0"></iframe>
      </div>
    </div>
  )
}

export default SpeakerStat