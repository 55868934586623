import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import '../styles/speakerPage.scss'
import axios from 'axios';
import SpeakerDesc from '../components/speakers/SpeakerDesc';
import SpeakerSpeach from '../components/speakers/SpeakerSpeach';
import Skeleton from '@mui/material/Skeleton';
import { useLocation, Navigate, useParams, useSearchParams } from "react-router-dom"
import MenuItem from '@mui/material/MenuItem';
import SpeakersList from 'src/components/report/SpeakersList';
import Select, { SelectChangeEvent } from '@mui/material/Select';

SpeakersPage.propTypes = {

};

function SpeakersPage(props) {

  const select = useRef('')

  const handleChange = (val) => {
    setSelected(val);
  };

  const [speakersList, setSpeakersList] = useState(null);

  const [allSpeakers, setAllSpeakers] = useState(null);
  const [speakers, setSpeakers] = useState([])
  const [selected, setSelected] = useState(0)
  const searchParams = new URLSearchParams(document.location.search)
  let { id } = useParams();
  const [stat, setStat] = useState(false)
  const [speakersDropdown, setSpeakersDropdown] = useState([])


  const filterSpeakersByName = (name) => {
    let filter = allSpeakers.filter(speaker => speaker.speaker.toLowerCase().includes(name.toLowerCase()))
    return setSpeakers(filter.length > 0 ? filter : allSpeakers);
  }


  useEffect(() => {
    loadData();
    axios.get(process.env.REACT_APP_BACKEND + '/api/reports_speakers?event_id=123&file_id=' + id, {
      headers: {
        'x-token': 'zini-da-ti-kazem'
      }
    }).then((data) => {


      const tmp = []
      let tmp_ind = 0
      for (let index = 0; index < data.data.length; index++) {
        if (searchParams.get('speaker') == data.data[index].speaker) {
          tmp_ind = index
        }
        tmp.push(data.data[index].speaker)
      }
      setSpeakersDropdown(tmp)
      setSpeakers(data.data)
      setSelected(tmp_ind)
      setAllSpeakers(data.data);

    })

  }, [])

  const loadData = () => {
    axios.get(process.env.REACT_APP_BACKEND + '/api/reports_main?event_id=123&file_id=' + id, {
      headers: {
        'x-token': 'zini-da-ti-kazem'
      }
    }).then((response) => {

      setSpeakersList(response.data["speakers"]);
    })
  }

  return (
    <div className='title-wrap'>
      <h1 className="report-page-header sp-rep">Statements

      </h1>
      <div className='speaker-wrap'>

        {
          speakers.length > 0 ?
            [
              <div className="report-page-body">

                <SpeakersList title={"Speakers"} speakers={speakers} handleChange={handleChange} filterSpeakersByName={filterSpeakersByName} />,
              </div>,
              <SpeakerDesc speakers={speakers} selected={selected} setSelected={setSelected} dropdown={speakersDropdown} stat={stat} />,
              <SpeakerSpeach spa={speakers[selected]} speech={speakers[selected]?.speech} />]
            : [<Skeleton variant="rectangular" width={'100%'} height={140} />, <Skeleton variant="rectangular" width={'100%'} height={240} />, <Skeleton variant="rectangular" width={'100%'} height={300} />]
        }


      </div>
    </div>

  );
}

export default SpeakersPage;