import React from 'react';
import PreviewModal from 'src/components/common/Modals/PreviewModal';
import iconSet from "src/assets/icon-set/selection.json";
import IcomoonReact from 'icomoon-react';
import { getSpeakerNameById } from 'src/utils/reportDataHelper';
import StackedBarChartSingle from 'src/components/common/Graphs/StackedBarChartSingle';

import { POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR } from 'src/utils/constants';
// const colors = [POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR]


function TopicModal({ previewModalOpen, closeTopicModal, selectedTopic, data, allSpeakers }) {

    const renderIconColor = (sentiment) => {
        switch (sentiment) {
            case -1:
                return NEGATIVE_ARGUMENT_COLOR
            case 0:
                return NEUTRAL_ARGUMENT_COLOR
            case 1:
                return POSITIVE_ARGUMENT_COLOR
        }
    }

    return (
        <PreviewModal
            open={previewModalOpen}
            onClose={closeTopicModal}
            title={"Said about: " + selectedTopic.topic}>
            {/* <StackedBarChart data={data} /> */}
            <div className="sentiment-graph">
                <StackedBarChartSingle data={data} />
            </div>
            {
                selectedTopic["arguments"] != undefined && selectedTopic["arguments"].map((argument, index) =>
                    <>
                        <div key={index} className="argument">
                            <div style={{ display: "flex", justifyContent: "space-around" }}>
                                <IcomoonReact
                                    className='argument-square-icon'
                                    iconSet={iconSet}
                                    size={16}
                                    icon="square"
                                    color={renderIconColor(argument.sentiment)}
                                />
                            </div>
                            <div>
                                <b style={{ borderBottom: "1px" + renderIconColor(argument.sentiment) + "solid" }}>{getSpeakerNameById(allSpeakers, argument.speakerId)}:</b>
                                <br />
                                {argument.argument}
                            </div>
                        </div>
                        <hr className="secondary" />
                    </>
                )
            }
        </PreviewModal>
    );
}

export default TopicModal;