import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "../views/HomePage";
import SpeakersPage from "../views/SpeakersPage";
import TranscriptPage from "../views/TranscriptPage";
import { CHAT_PAGE_ROUTE, HOME_PAGE_ROUTE, REPORT_PAGE_ROUTE, SPEAKERS_PAGE_ROUTE, TRANSCRIPT_PAGE_ROUTE } from "../utils/routes";
import ChatPage from "../views/ChatPage";
import ReportPage from "../views/ReportPage";
import MainLayout from '../components/layout/MainLayout';
import ChatLayout from '../components/layout/ChatLayout';
import HomeLayout from '../components/layout/HomeLayout';
import SpeakerLayout from '../components/layout/SpeakerLayout';

function AppRouter() {
    return (
        <BrowserRouter>
            <Routes>

                <Route path={'/:id'+HOME_PAGE_ROUTE} element={
                    <HomeLayout pageTitle="Home" >
                        <HomePage />
                    </HomeLayout>
                } />

                <Route path={'/:id'+TRANSCRIPT_PAGE_ROUTE} element={
                    <MainLayout pageTitle="Transcript" >
                        <TranscriptPage />
                    </MainLayout>
                } />

                <Route path={'/:id'+CHAT_PAGE_ROUTE} element={
                    <ChatLayout pageTitle="Chat" >
                        <ChatPage />
                    </ChatLayout>
                } />

                <Route path={'/:id'+SPEAKERS_PAGE_ROUTE} element={
                    <SpeakerLayout pageTitle="Speakers" >
                        <SpeakersPage />
                    </SpeakerLayout>
                } />

                <Route path={'/:id'+REPORT_PAGE_ROUTE} element={
                    <MainLayout pageTitle="Report" >
                        <ReportPage />
                    </MainLayout>
                } />

                <Route path="*" element={'ERROR 404'} />

            </Routes>
        </BrowserRouter>
    );
}

export default AppRouter;