import React from 'react';
import iconSet from "../assets/icon-set/selection.json";
import IcomoonReact from 'icomoon-react';
import { useNavigation } from '../hooks/useNavigation';
import { CHAT_PAGE_ROUTE, REPORT_PAGE_ROUTE, SPEAKERS_PAGE_ROUTE, TRANSCRIPT_PAGE_ROUTE } from '../utils/routes';
import { useLocation, Navigate, useParams, useSearchParams } from "react-router-dom"

import logo from "../assets/img/Diplo-logo-transparent.png"
import { useResponsive } from 'src/hooks/useResponsive';

function HomePage(props) {

    const { navigateTo } = useNavigation();
    let { id } = useParams();
    const { mobileOnly } = useResponsive();
    // const mobileOnly = false;

    return (
        <div id="home-page-container">

            <div className="home-page-header">

                <div style={{ display: "flex", justifyContent: "center" }}>
                    <img src={logo} alt="" />
                </div>

                <div>
                    {/* <h1>Dive into a seamless experience where AI meets communication. </h1> */}
                    <h2>Follow UNGA78 with DiploAI reports LIVE</h2>
                </div>

            </div>

            <div className="home-page-body">

                {/* <div className='button-card' style={{ background: "#6eb1e1" }}>
                    <IcomoonReact
                        iconSet={iconSet}
                        size={216}
                        icon="transcript"
                        onClick={() => navigateTo(TRANSCRIPT_PAGE_ROUTE)}
                    />
                    <h2>Transcript</h2>
                </div> */}

                <div className='button-card'
                    style={mobileOnly ? { flexBasis: "60%", background: "#4eb13a" } : { background: "#4eb13a" }}
                >
                    <IcomoonReact
                        iconSet={iconSet}
                        size={mobileOnly ? 108 : 216}
                        icon="report"
                        onClick={() => navigateTo('/' + id + REPORT_PAGE_ROUTE)}
                    />
                    <h2>Summary Report</h2>
                </div>

                <div
                    className='button-card'
                    style={mobileOnly ? { flexBasis: "40%", background: "#f9704e" } : { background: "#f9704e" }}
                >
                    <IcomoonReact
                        iconSet={iconSet}
                        size={mobileOnly ? 108 : 216}
                        icon="ai-chat"
                        onClick={() => navigateTo('/' + id + CHAT_PAGE_ROUTE)}
                        style={mobileOnly ? { flexBasis: "40%" } : {}}
                    />
                    <h2>AI Chat</h2>
                </div>

                <div
                    className='button-card'
                    style={mobileOnly ? { flexBasis: "40%", background: "#f7cfa2" } : { background: "#f7cfa2" }}
                >
                    <IcomoonReact
                        iconSet={iconSet}
                        size={mobileOnly ? 108 : 216}
                        icon="speakers-analysis"
                        onClick={() => navigateTo('/' + id + SPEAKERS_PAGE_ROUTE)}
                    />
                    <h2>Statements</h2>
                </div>

            </div>

        </div>
    );
}

export default HomePage;