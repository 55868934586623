import React,{useEffect,useState,useRef} from 'react'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Massage_single from './Massage_single';
import BounceDots from './BounceDots';
import axios from "axios";
import {useParams } from "react-router-dom"

function Chat({user_id}) {

  const [chat,setChat]=useState([])
  let [inc,setIn]=useState(0)
  const ref=useRef('')
  const btn=useRef('')
  const chatView=useRef(null)
  const [inLoad,setInLoad]=useState(null)
  let {id}=useParams();
  



  const sendData=()=>{
    if (ref.current?.value!==undefined){
      setIn(inc++)
      setChat([...chat,{id:inc,'type':1,'msg':ref.current.value}])

      setInLoad(true)
      axios.post(process.env.REACT_APP_BACKEND+'/api/advisor/chat',{
        conversation_id: user_id,
        message: ref.current.value,
        file_id: 'UNGA78'
      }).then((data) => {
        setIn(inc++)
        var bot=JSON.parse(data.data)
        setChat(chat=>[...chat,{id:inc,'type':0,'msg':bot['answer']}])
        setChat(chat=>[...chat,{id:inc,'type':2,'msg':bot['sources']}])

        setInLoad(false)
      }).catch((error) => { // error is handled in catch block
        if (error.response) { // status code out of the range of 2xx
          console.log("Data :" , error.response.data);
          console.log("Status :" + error.response.status);
          setChat(chat=>[...chat,{id:inc,'type':0,'msg':error.response.status+': '+error.response.data?.detail,error:1}])
        } else if (error.request) { // The request was made but no response was received
          console.log('no response')
          setChat(chat=>[...chat,{id:inc,'type':0,'msg':'no response',error:1}])

          console.log(error.request);
        } else {// Error on setting up the request
          console.log('Error', error.message);
        }
        setInLoad(false)

      });
      ref.current.value=''

    }
  }

  const onClick=()=>{
    if (ref.current?.value!==''){
      sendData()
     
    }
  }
  const handleKeypress = e => {   
    if (e.keyCode === 13) {      
      btn.current.click();
    }  
  };

  useEffect(() => {
    if(chatView.current!=null){
      chatView.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat])
  

  return (
    

        <div className="room">
          <h1>AI Chat</h1>
          <div className='chat' >
            {chat.map((e)=>{
              return  <Massage_single msg={e.msg} type={e.type} re={chatView} error={e?.error}></Massage_single>
            })}
            {inLoad?<Massage_single msg={<BounceDots/>} type={0} re={chatView}></Massage_single>:null}
            
          </div>
          <div className='input-box'>
            <TextField id="outlined-basic" className='test' variant="outlined" inputRef={ref} onKeyUp={handleKeypress}/>
            <Button variant="contained" className='send' onClick={onClick}  ref={btn}>Send</Button>

          </div>
        </div>

  )
}

export default Chat