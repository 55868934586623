import React from 'react';
import PropTypes from 'prop-types';

TranscriptPage.propTypes = {
    
};

function TranscriptPage(props) {
    return (
        <div>
            Transcript Page
        </div>
    );
}

export default TranscriptPage;