import React, { useMemo, useState } from "react";
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Cell,
    Text,
    Tooltip,
    Legend
} from "recharts";
import { POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR } from 'src/utils/constants';


function renderDataName(item) {
    switch (item) {
        case "positivePercentage":
            return "Positive arguments";
        case "neutralPercentage":
            return "Neutral arguments";
        case "negativePercentage":
            return "Negative arguments";
    }
}

// function getTotalNumber(item) {
//     switch (item.name) {
//         case "positivePercentage":
//             return item.payload.positive;
//         case "neutralPercentage":
//             return item.payload.neutral;
//         case "negativePercentage":
//             return item.payload.negative;
//     }
// }

function getTotalNumber(data, item) {
    // return JSON.stringify(data[0].);
    switch (item) {
        case "positivePercentage":
            return data[0].positive;
        case "neutralPercentage":
            return data[0].neutral;
        case "negativePercentage":
            return data[0].negative;
    }
}

function StackedBarChartSingle({ data }) {

    const colors = [POSITIVE_ARGUMENT_COLOR, NEUTRAL_ARGUMENT_COLOR, NEGATIVE_ARGUMENT_COLOR]

    return (
        <ResponsiveContainer width={"100%"} height={100} debounce={50}>
            <BarChart
                data={data}
                layout="vertical"
                // margin={{ left: 50 }}
            >

                <XAxis hide axisLine={false} type="number" domain={[0, 100]} />
                <YAxis
                hide
                    yAxisId={0}
                    dataKey={"topic"}
                    type="category"
                    axisLine={false}
                    tickLine={false}
                />

                <Legend
                    payload={
                        ["positivePercentage", "neutralPercentage", "negativePercentage"].map(
                            (item, index) => ({
                                id: item,
                                type: "square",
                                value: `${getTotalNumber(data, item)} ${renderDataName(item)}`,
                                // value: `3 ${renderDataName(item)} ${item}`,
                                color: colors[index % colors.length]
                            })
                        )
                    }
                />

                <Bar dataKey="positivePercentage" stackId="sentiment" fill={colors[0]} />
                <Bar dataKey="neutralPercentage" stackId="sentiment" fill={colors[1]} />
                <Bar dataKey="negativePercentage" stackId="sentiment" fill={colors[2]} />
            </BarChart>
        </ResponsiveContainer>
    );
}

export default StackedBarChartSingle;