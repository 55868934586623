import React from 'react';
import PropTypes from 'prop-types';
import Chatroom from '../components/chatroom/Chat';
import { useState,useEffect,useRef } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import axios from "axios";

ChatPage.propTypes = {
    
};



const theme = createTheme({
  status: {
    danger: '#e53e3e',
  },
  palette: {
    primary: {
      main: '#00818c',
      darker: '#006c75',
    }
  },
});

function ChatPage() {
  const [info,setInfo]=useState(false);
  const [user_id,setUserId]=useState('')
  const backend="https://dev-ai.diplomacy.edu:8511"
  useEffect(() => {
    if(!user_id){
      axios.get(backend+'/').then((data)=>{
        setUserId(data.data.id) 
      }).catch((e)=>{console.log(e);})
        
    }
  }, [user_id])
  
  
  return (
    <>
    <ThemeProvider theme={theme}>
      <Chatroom user_id={user_id}></Chatroom>
    </ThemeProvider>
    </>
  );
}


export default ChatPage;